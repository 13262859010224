/*
 * @Description:
 * @Author: YuKi
 * @Date: 2022/09/29
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024/12/18 20:20:12
 */
//key为与后端返回菜单的name相对应

export default {
  home: '/app/home', // 首页
  mainPage: '/app/mainSystem/mainSystemInterface', // 系统主页面111
  universalRemoteSettings: '/app/basicManagementModule/universalRemoteSettings', //远程系统设置111
  systemList: '/app/systemList', // 场站列表
  channelList: '/app/channelList', // 通道列表
  pandect: '/app/energyStorageData/pandect', // 储能数据总览
  controlStrategy: '/app/controlStrategy', // 控制策略
  systemAdvancedParameters: '/app/basicManagementModule/SysAdvParameters', //系统高级参数
  equipmentParameter: '/app/basicManagementModule/equipmentParameter', // 设备参数配置
  deviceManage: '/app/basicManagementModule/deviceManage', // 设备管理
  deviceManageList: '/app/basicManagementModule/deviceManageList', // 设备管理列表
  loadPowerParameterList: '/app/basicManagementModule/loadPowerParameterList', // 负载功率参数列表
  maintenanceParameter: '/app/basicManagementModule/maintenanceParameter', // 维护参数配置
  chargingPileData: '/app/chargingPileData', // 充电桩数据
  pscdata: '/app/controlStrategy/pscdata', // 储能数据 PCS
  powercurve: '/app/powerCurve/powerCurve', // 功率曲线
  electricityStatistics: '/app/electricityStatistics/electricityStatistics', // 电量统计
  dieselEngineData: '/app/energyStorageData/dieselEngineData', // 储能数据-柴油机数据
  clusterData: '/app/energyStorageData/clusterData', // 储能数据-电池簇数据
  firmwareUpdate: '/app/systemManagement/firmwareUpdate', // 系统管理 固件版本更新
  runningLog: '/app/backstageManagement/runningLog', // 运行日志
  userLog: '/app/backstageManagement/userLog', // 用户日志
  userInformationModification:
    '/app/authManagement/userInformationModification', // 用户信息修改
  cancelAccount: '/app/authManagement/cancelAccount', // 注销账号
  productInformation: '/app/dataCenter/productInformation', // 产品资料
  productList: '/app/dataCenter/productList', // 产品资料列表
  dataCenterManage: '/app/dataCenter/dataCenterManage', // 资料中心管理
  dataColumnManage: '/app/dataCenter/dataColumnManage', // 资料栏目管理
  snManagement: '/app/systemManagement/snManagement', // SN管理
  repairRecord: '/app/systemManagement/repairRecord', // 维修记录查询
  runLog: '/app/runLog', // 运行日志
  reportManagement: '/app/reportManagement', // 报表管理
  welcome: '/app/welcome', // 引导页
  warningMessage: '/app/warningMessage', // 告警信息
  upgradeLog: '/app/upgradeLog', // 升级日志
  runningLogList: '/app/RunningLog', // 运行日志
  equipmentParameterLog: '/app/equipmentParameterLog', // 设备参数操作日志
  deviceListLog: '/app/deviceListLog', // 设备列表操作日志
  licenseManagement: '/app/systemManagement/licenseManagement', // license管理
  installNewSystem: '/app/systemSettings/installNewSystem', // 安装新系统
  associateNewSystem: '/app/systemSettings/associateNewSystem', // 关联新系统
  depotAddNewChannel: '/app/systemSettings/depotAddNewChannel', // 场站增加新通道
  basicSysInfoSettings: '/app/systemSettings/basicSysInfoSettings', // 系统基本信息设置
  singleBatteryData: '/app/controlStrategy/singleBatteryData', // 单体电池数据
  batteryStackData: '/app/energyStorageData/batteryStackData', // 电池堆数据
  changePassword: '/app/authManagement/changePassword', // 修改密码
  myMessages: '/app/authManagement/myMessage', // 我的消息页
  electricityPriceSetting: '/app/authManagement/electricityPriceSetting', // 电价设置
  equipmentList: '/app/energyStorageData/equipmentList', // 设备列表
  chargingPileSettings: '/app/energyStorageData/chargingPileSettings', // 充电桩设置
  incomeAnalysis: '/app/incomeAnalysis/incomeAnalysis', //收益分析
  contactInfo: '/app/contactInfo', // 联系信息
  privacyPolicy: '/app/privacyPolicy', // 隐私政策
  customerFeedback: '/app/customerFeedback', // 客户反馈
  topoConfig: '/app/webTopo/topoConfig',
  topoDebug: '/app/webTopo/topoDebug',
  templateManage: '/app/webTopo/templateManage',
  error_404: '/app/example/404',
  error_403: '/app/example/403',
  deviceOperatingData: '/app/energyStorageData/DeviceOperatingData',
  energyStorageSystemMaintenance:
    '/app/energyStorageData/energyStorageSystemMaintenance',
  updateList: '/app/updateManagement/updateList', // 升级管理-升级列表
  batchUpgrade: '/app/updateManagement/batchUpgrade', // 升级管理->批量升级
  modelList: '/app/updateManagement/modelList', // 升级管理-模块列表
  productSuggestions: '/app/productSuggestions', // 产品建议
  dataSearch: '/app/dataSearch', //BMS-数据查询
  paramsConfig: '/app/paramsConfig', // BMS-参数配置
  systemOperation: '/app/systemOperation', //BMS-系统操作
  realData: '/app/realData', //BMS-实时数据
  historyData: '/app/historyData', //BMS-告警信息
  warningInfo: '/app/warningInfo', //BMS-历史数据
  realDataStackDataDetail: '/app/realDataStackDataDetail', //实时数据--堆数据详情
  realDataClusterDataList: '/app/realDataClusterDataList', //实时数据--簇数据列表
  heatPipeEquipmentList: '/app/heatPipeEquipmentList',
  realDataClusterDataDetail: '/app/realDataClusterDataDetail',
  packageList: '/app/packageList',
  packageDetail: '/app/packageDetail',
  stationAddNewSystem: '/app/systemSettings/stationAddNewSystem', //bms--场站新增新系统
  addNewStation: '/app/systemSettings/addNewStation', //bms--增加新场站
  remoteControl: '/app/systemManagement/remoteControl', //bms--远程控制
  equipmentFailureLog: '/app/infoList/equipmentFailureLog', //设备故障日志
  PCSRemoteParameterSetting:'/app/energyStorageSystemMaintenance/PCSRemoteParameterSetting' //PCS远程参数设置
};
