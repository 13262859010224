/*
 * @Description:Redux  Action
 * @Author: Summer.Chen
 * @Date: 2022-10-17 14:21:10
 * @LastEditTime: 2024/11/24 13:23:17
 * @LastEditors: Please set LastEditors
 */
import { app } from '@/constants/actionType';

export const spinLoading = loading => {
  return { type: app.SPIN_LOADING, spinLoading: loading };
};
export const updateToPath = toPath => {
  return { type: app.UPDATE_TOPATH, toPath: toPath };
};
export const updateAccessMenu = data => {
  return { type: app.UPDATE_ACCESSMENU, ...data };
};
export const updateModule = module => {
  return { type: app.UPDATE_MODULE, ...module };
};
export const updateLanguage = lang => {
  localStorage.setItem('language', lang.language);
  return { type: app.UPDATE_LANGUAGE, ...lang };
};
// 更新系统SN
export const updateSysSn = sysSn => {
  return { type: app.UPDATE_SYS_SN, sysSn: sysSn };
};
// 更新站点列表id
export const updateDepotId = depotId => {
  return { type: app.UPDATE_DEPOT_ID, depotId: depotId };
};
export const updateSysList = isRefresh => {
  return { type: app.UPDATE_SYS_LIST, isRefresh: isRefresh };
};
export const updateSysRole = sysRole => {
  localStorage.setItem('sysRole', [sysRole]);
  return { type: app.UPDATE_SYS_ROLE, sysRole: sysRole };
};
export const setProjectName = projectName => {
  return { type: app.SET_PROJECT_NAME, projectName: projectName };
};
export const updateDepotNation = depotNation => {
  return { type: app.UPDATE_DEPOT_NATION, depotNation: depotNation };
};
export const updateTheme = theme => {
  return { type: app.UPDATE_THEME, theme: theme };
};
export const updateDataType = dataType => {
  localStorage.setItem('dataType', dataType);
  return { type: app.DATA_TYPE, dataType: dataType };
};
export const updateStationType = stationType => {
  localStorage.setItem('stationType', stationType);
  return { type: app.UPDATE_STATION_TYPE, stationType: stationType };
};
export const updateShowChangeStation = showChangeStation => {
  localStorage.setItem('showChangeStation', showChangeStation);
  return {
    type: app.UPDATE_SHOW_CHANGE_STATION,
    showChangeStation: showChangeStation
  };
};
export const updateBlmuCount = blmuCount => {
  localStorage.setItem('BlmuCount', blmuCount);
  return { type: app.UPDATE_BLMUCOUNT, blmuInfo: blmuCount };
};
export const updateBcmuCount = bcmuCount => {
  localStorage.setItem('BcmuCount', bcmuCount);
  return { type: app.UPDATE_BCMUCOUNT, bcmuCount: bcmuCount };
};
export const updateBlmuInfo = blmuInfo => {
  localStorage.setItem('BlmuInfo', JSON.stringify(blmuInfo));
  return { type: app.UPDATE_BLMUINFO, blmuInfo: blmuInfo };
};
